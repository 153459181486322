.confimDialog{
    z-index:100000;
}

.tags_display {
    font-size:10px;
    /*font-style:italic;*/
    color:#0f5392;
    background-color: #9fcdff;
    padding:3px;
}



.otherSkillsDisplay{
    font-size:10px;
    font-style:italic;
}