.split {
  min-height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.left {
  left: 0;
  background-color: #0a1727;
  color: #fff;
}

.bulletlist-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  background: #0A1727;
  color: #FFF;
  font-size: 14px;
  min-height: 100px;
  max-width: 500px;
  margin: 15px auto;
  padding: 15px unset !important;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 12px;
  border-radius: 4px;
}

.bulletlist-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-right: 30px;
  justify-self: center;
  text-align: center;
}

.right {
  right: 0;
  background-color: #3d9ce0;
  color: #fff;
}

.right .col-md-8 {
  max-width: unset !important;
}

.logo-right {
  display: unset;
  max-width: 200px;
  margin-top: 150px;
}

.terms-center {
  text-align: unset !important;
}

.right a {
  color: #fff;
}
.terms-right::before {
  left: 0rem;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.card_fieldset {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15);
  border-radius: 4px;
  border: none;
  font-size: 0;
  padding: 15px;
}

.actionButton {
  background: transparent;
  outline: none;
  border: none;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0;
  font-size: 24px;
}

@media (min-width: 30em) {
  .terms-right::before {
    left: -1.5rem;
  }
}

@media (min-width: 700px) {
  .terms-right::before {
    left: 0rem;
  }
}

@media (min-width: 790px) {
  .terms-right::before {
    left: -1.5rem;
  }
}

@media (min-width: 50em) {
  .bulletlist-register {
    width: 470px;
    max-width: unset;
  }
  .logo-right {
    max-width: 200px;
  }
  .terms-right::before {
    left: -1.5rem;
  }
}

@media (min-width: 70em) {
  .split {
    width: 50%;
  }
  .left {
    display: flex;
  }

  .logo-right {
    display: none;
  }
}
