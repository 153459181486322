@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

.btn-flat {
  background-color: #0b4fbd;
  color: white;
}
.spaced-between {
  display: flex;
  justify-content: space-between;
}
.page-item.active .page-link {
  background-color: #0b4fbd;
  color: white;
}

.page-link {
  color: #0b4fbd;
}

.modal-backdrop {
  min-width: 100%;
  min-height: 100%;
}

table.dataTable tbody>tr.selected,
table.dataTable tbody>tr>.selected {
  background-color: #0b4fbd;
}

.custom-select {
  width: 80px;
}

.pagination {
  float: right;
}

.dataTables_filter {
  float: right;
}
.modal-product .modal-content{
  padding: 26px 41px 41px 41px !important;
}
.modal-product .modal-body {
  padding: 0px !important;
}
.modal-product button { 
  color: black;
  text-shadow: none;
  border: 2px solid black;
  border-radius: 100%;
  height: 49px;
  width: 49px;
  padding: 0px !important;
  opacity: 1;
 }
 


.modal-product .close>span {
  font-size: 3rem;
    font-weight: 100;
    line-height: 0.5;
}
.modal-product .close{
  transform: scale(0.6);
}
// 39
table.dataTable thead>tr>th.sorting,
table.dataTable thead>tr>th.sorting_asc,
table.dataTable thead>tr>th.sorting_desc,
table.dataTable thead>tr>th.sorting_asc_disabled,
table.dataTable thead>tr>th.sorting_desc_disabled,
table.dataTable thead>tr>td.sorting,
table.dataTable thead>tr>td.sorting_asc,
table.dataTable thead>tr>td.sorting_desc,
table.dataTable thead>tr>td.sorting_asc_disabled,
table.dataTable thead>tr>td.sorting_desc_disabled {
  cursor: pointer;
  position: relative;
  padding-right: 27px;
}

// 54
table.dataTable thead>tr>th.sorting:before,
table.dataTable thead>tr>th.sorting:after,
table.dataTable thead>tr>th.sorting_asc:before,
table.dataTable thead>tr>th.sorting_asc:after,
table.dataTable thead>tr>th.sorting_desc:before,
table.dataTable thead>tr>th.sorting_desc:after,
table.dataTable thead>tr>th.sorting_asc_disabled:before,
table.dataTable thead>tr>th.sorting_asc_disabled:after,
table.dataTable thead>tr>th.sorting_desc_disabled:before,
table.dataTable thead>tr>th.sorting_desc_disabled:after,
table.dataTable thead>tr>td.sorting:before,
table.dataTable thead>tr>td.sorting:after,
table.dataTable thead>tr>td.sorting_asc:before,
table.dataTable thead>tr>td.sorting_asc:after,
table.dataTable thead>tr>td.sorting_desc:before,
table.dataTable thead>tr>td.sorting_desc:after,
table.dataTable thead>tr>td.sorting_asc_disabled:before,
table.dataTable thead>tr>td.sorting_asc_disabled:after,
table.dataTable thead>tr>td.sorting_desc_disabled:before,
table.dataTable thead>tr>td.sorting_desc_disabled:after {
  position: absolute;
  display: block;
  opacity: 0.125;
  right: 10px;
  line-height: 12px;
  font-size: 0.8em;
}

// 67
table.dataTable.table-data-sort thead>tr>th.sorting:before,
table.dataTable.table-data-sort thead>tr>th.sorting_asc:before,
table.dataTable.table-data-sort thead>tr>th.sorting_desc:before,
table.dataTable.table-data-sort thead>tr>th.sorting_asc_disabled:before,
table.dataTable.table-data-sort thead>tr>th.sorting_desc_disabled:before,
table.dataTable.table-data-sort thead>tr>td.sorting:before,
table.dataTable.table-data-sort thead>tr>td.sorting_asc:before,
table.dataTable.table-data-sort thead>tr>td.sorting_desc:before,
table.dataTable.table-data-sort thead>tr>td.sorting_asc_disabled:before,
table.dataTable.table-data-sort thead>tr>td.sorting_desc_disabled:before {
  bottom: 50%;
  content: "▲";
  content: "▲" /"";
}

// 77
table.dataTable.table-data-sort thead>tr>th.sorting:after,
table.dataTable.table-data-sort thead>tr>th.sorting_asc:after,
table.dataTable.table-data-sort thead>tr>th.sorting_desc:after,
table.dataTable.table-data-sort thead>tr>th.sorting_asc_disabled:after,
table.dataTable.table-data-sort thead>tr>th.sorting_desc_disabled:after,
table.dataTable.table-data-sort thead>tr>td.sorting:after,
table.dataTable.table-data-sort thead>tr>td.sorting_asc:after,
table.dataTable.table-data-sort thead>tr>td.sorting_desc:after,
table.dataTable.table-data-sort thead>tr>td.sorting_asc_disabled:after,
table.dataTable.table-data-sort thead>tr>td.sorting_desc_disabled:after {
  top: 50%;
  content: "▼";
  content: "▼" /"";
}

table.dataTable.table-data-sort thead>tr>th.sorting_asc:before,
table.dataTable.table-data-sort thead>tr>th.sorting_desc:after,
table.dataTable.table-data-sort thead>tr>td.sorting_asc:before,
table.dataTable.table-data-sort thead>tr>td.sorting_desc:after {
  opacity: 0.6;
}

// 87
table.dataTable.table-data-sort thead>tr>th.sorting_asc:before,
table.dataTable.table-data-sort thead>tr>th.sorting_desc:after,
table.dataTable.table-data-sort thead>tr>td.sorting_asc:before,
table.dataTable.table-data-sort thead>tr>td.sorting_desc:after {
  opacity: 0.6;
}




// #sidebar-menu {
//   width: 150px;
//   float: left;
// }

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@400;600&family=Rubik&display=swap");
$dosis: "Dosis", sans-serif;
$rubik: "Rubik", sans-serif;

// Colors
$blue: #315ed1;
$white: #fff;
$light: #e5e5e5;
$grey: #aca7ab;
$black: #2f242c;

$standardTransition: 0.25s ease-out;

.ft-dosis {
  font-family: $dosis;
}

.ft-rubik {
  font-family: $rubik;
}

.fw-bold {
  font-weight: 600;
}

.text-blue {
  color: $blue !important;
}

.text-light {
  color: $light !important;
}

.text-grey {
  color: $grey !important;
}

.text-black {
  color: $black !important;
}

.bg-blue {
  background-color: $blue !important;
}

.bg-light {
  background-color: $light !important;
}

.bg-grey {
  background-color: $grey !important;
}

.bg-black {
  background-color: $black !important;
}

@mixin mobile {
  @media only screen and (max-width: 767px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

a {
  color: $blue;
  transition: $standardTransition;

  &:hover {
    text-decoration: none;
    color: darken($color: $blue, $amount: 25%);
  }
}

html,
body,
#root {
  height: 100%;
}

body,
#root {
  display: flex;
  flex-direction: column;
  font-family: $rubik;
  color: $black;
}

#root {
  overflow-x: hidden;
}

.page-wrapper {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

.main-nav {
  margin-top: 2rem;

  .brand {
    font-size: 20px;
    line-height: 36px;
    color: $blue;

    @include desktop {
      font-size: 30px;
    }

    img {
      width: 40px;
      margin-right: 1rem;

      @include desktop {
        width: 56px;
      }
    }
  }
}

.language-select {
  border: 0;
  outline: 0;
  padding: 12px;
  color: $grey;

  &:focus {
    border: 0;
    outline: 0;
  }
}

footer {
  text-align: center;
  padding-top: 56px;
  padding-bottom: 56px;

  * {
    color: $light;
  }
}

.welcome-text {
  margin-top: 72px;
  line-height: 81px;
  font-size: 64px;
  font-family: $dosis;
  font-weight: 600;
}

.text-signin {
  margin-top: 72px;
  line-height: 81px;
  font-size: 64px;
  font-family: $dosis;
  font-weight: 600;
}

.register-text {
  margin-top: 20px;
  font-family: Inter;
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;

  @include desktop() {
    font-size: 36px;
    line-height: 48px;
  }
}

.landing-button {
  max-width: 384px;
  width: 98%;
  background: $white;
  border: 1px solid $blue;
  box-sizing: border-box;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 36px;
  font-family: $dosis;
  box-shadow: 0 8px 16px rgba($blue, 0.2);
  transition: $standardTransition;
  padding: 6px;
  font-size: 24px;
  color: $blue;

  @include desktop {
    font-size: 36px;
    padding: 22px;
  }

  &:hover {
    background-color: darken($color: $white, $amount: 5%);
  }

  &.has-bg {
    background-color: $blue;
    background-image: linear-gradient(180deg, #3b71dc 0%, #315ed1 100%);
    color: $white;

    &:hover {
      background-color: darken($color: $blue, $amount: 25%);
    }
  }
}

.form-field {
  max-width: 384px;
  width: 98%;

  &.is-search {
    max-width: unset;
    width: 100%;
    margin-left: auto;
    display: flex;
    margin-bottom: 2rem;

    @include desktop() {
      max-width: 208px;
    }
  }
}

.dot {
  width: 24px;
  height: 24px;
  border: 1px solid $blue;
  display: inline-flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  color: $blue;

  &:last-of-type {
    margin-left: 1em;
  }
}

.dot_selected {
  width: 24px;
  height: 24px;
  display: inline-flex;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  color: $white;
  background-color: $blue;

  &:last-of-type {
    margin-left: 1em;
  }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA4IDgiPjxwYXRoIGZpbGw9IiMzMTVFRDEiIGQ9Ik02LjU2NC43NWwtMy41OSAzLjYxMi0xLjUzOC0xLjU1TDAgNC4yNiAyLjk3NCA3LjI1IDggMi4xOTN6Ii8+PC9zdmc+");
}

.custom-control-input:checked~.custom-control-label::before {
  color: #fff;
  border-color: $grey;
  background-color: $white;
}

.guide-links {
  .pdf-placeholder {
    width: 56px;
    height: 56px;
    border: 1px solid #315ed1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    border-radius: 8px;
  }

  .guide-item-title {
    font-size: 18px;
    line-height: 24px;
    color: $black;
  }

  .guide-item-date {
    font-size: 14px;
    line-height: 24px;
    color: $grey;
  }
}

.sidebar-wrapper {
  position: relative;
  z-index: 1;

  @include desktop {
    position: absolute;
  }
}

// #sidebar-menu {
//   margin-top: 13px;
//   border-bottom: 1px solid #e5e5e5;

//   @include desktop {
//     // margin-top: 120px;
//     border-bottom: 0;
//   }

//   .normal {
//     color: $black;
//     //font-weight: 600;
//     //font-size: 18px;
//     //line-height: 24px;
//     font-family: $dosis;

//     &.selected {
//       color: $white;
//       border-bottom: 2px solid $blue;
//       padding-bottom: 4px;

//       @include desktop() {
//         border-bottom: 0;
//       }
//     }

//     @include desktop() {
//       font-size: 18px;
//       line-height: 26px;
//     }
//   }

//   .navbar-text {
//     width: 100%;
//     padding-bottom: 0;
//   }

//   .nav-link {
//     display: inline-block;
//     padding-bottom: 0px;

//     @include desktop() {
//       display: block;
//     }
//   }
// }

#homeContainer {
  margin-top: 28px;

  //@include desktop() {
  //  margin-top: 96px;
  //}
}

#answersTableContainer {
  table {
    thead {
      background-color: $blue;
      color: $white;
    }

    @include mobile {
      font-size: 13px;
    }
  }

  .buttons-excel {
    max-width: 384px;
    width: 98%;
    background: $white;
    border: 1px solid $blue;
    box-sizing: border-box;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    line-height: 36px;
    font-family: $dosis;
    box-shadow: 0 8px 16px rgba($blue, 0.2);
    transition: $standardTransition;
    padding: 6px;
    font-size: 16px;
    background-color: $blue;
    background-image: linear-gradient(180deg, #3b71dc 0%, #315ed1 100%);
    color: $white;

    @include desktop {
      padding: 11px;
      font-size: 24px;
    }

    &:hover {
      background-color: darken($color: $blue, $amount: 25%);
    }
  }

  .paginate_button {
    &.previous {
      margin-left: auto;
    }

    .page-link {
      padding: 6px;
      line-height: 36px;
      font-family: $dosis;
      font-size: 16px;

      @include desktop {
        padding: 11px;
        font-size: 24px;
      }
    }
  }
}

body {
  font-family: Helvetica, sans-serif;
}

.App {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: whitesmoke;
}

.App .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  background-color: rgba(3, 14, 31, 0);
  padding: 0 25px;
}

.App .header h2 {
  font-family: monospace;
  font-size: 25px;
}

.primary-btn {
  background-color: rgb(16, 96, 172);
  border: 1px solid rgb(16, 96, 172);
  padding: 24px 40px;
  font-size: 24px;
  color: whitesmoke;
  cursor: pointer;
  margin: 14px;
  border-radius: 5px;
  transition: all 0.2s;
}

.primary-btn:hover {
  background-color: rgb(15, 83, 146);
}

.primary-btn:active {
  background-color: rgb(11, 61, 107);
}

.secondary-btn {
  background-color: #787878;
  border: 1px solid rgb(7, 127, 131);
  padding: 16px 28px;
  font-size: 16px;
  color: whitesmoke;
  cursor: pointer;
  margin: 8px;
  border-radius: 5px;
  transition: all 0.2s;
}

.secondary-btn:hover {
  background-color: rgb(6, 96, 99);
}

.secondary-btn:active {
  background-color: rgb(3, 61, 63);
}

.next-slide-btn,
.prev-slide-btn {
  background-color: rgba(208, 217, 235, 0.623);
  padding: 12px;
  border: 1px solid rgb(95, 95, 95);
  box-shadow: 0 0 5px 5px rgba(2, 5, 10, 0.445);
  border-radius: 50%;
  margin: 5px;
  cursor: pointer;
  transition: all 0.1s;
}

.next-slide-btn:hover,
.prev-slide-btn:hover {
  background-color: rgba(208, 217, 235, 0.767);
}

.next-slide-btn:active,
.prev-slide-btn:active {
  background-color: rgba(208, 217, 235, 0.856);
  box-shadow: none;
}

.next-slide-btn svg,
.prev-slide-btn svg {
  width: 32px;
  height: 32px;
  fill: whitesmoke;
}

.login-btn {
  background-color: #ef6c00;
  border: 1px solid #ff8f00;
  padding: 5px 35px;
  font-size: 20px;
  color: whitesmoke;
  cursor: pointer;
  margin: 10px;
  border-radius: 25px;
  box-shadow: 0 0 2px 1px rgba(239, 108, 0, 0.795);
  transition: all 0.3s;
}

.login-btn:hover {
  background-color: #c75a02;
}

.login-btn:active {
  background-color: #ac5005;
}

.mobile-menu-container {
  display: block;
  background-color: white;
  z-index: 100;
  position: absolute;
  top: 80px;
  right: 0;
  width: 250px;
}

.mobile-menu-container .menu-item {
  color: black;
  padding: 15px;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #ddd;
  transition: all 0.3s;
}

.mobile-menu-container .menu-item:active {
  background-color: #ccc;
}

.mobile-menu {
  display: none;
}

.desktop-menu {
  display: flex;
  align-items: center;
}

.brand {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.brand img {
  width: 96px;
}

.footer {
  z-index: 6;
  padding-bottom: 10px;
}

.footer .privacy-policy {
  font-size: 16px;
  margin-bottom: 10px;
  color: #6b97e7;
  cursor: pointer;
  text-decoration: underline;
  display: inline-block;
  font-weight: bold;
}

@media screen and (max-height: 843px) {
  .primary-btn {
    padding-top: 15px;
  }

  .secondary-btn {
    padding-top: 15px;
  }
}

@media screen and (max-width: 644px) {
  iframe.enlargeMobile,
.enlargeMobile
{
  min-height: 100vh;
}
  .primary-btn {
    font-size: 17px;
    padding: 8px 35px;
  }

  .secondary-btn {
    font-size: 17px;
    padding: 8px 35px;
  }

  .mobile-menu {
    display: block;
  }

  .desktop-menu {
    display: none;
  }

  .brand {
    display: flex;
    align-items: center;
  }

  .brand h2 {
    font-size: 20px !important;
  }

  .brand img {
    width: 64px;
  }
}

.survey-section {
  min-height: 100vh;
  width: 100vw;
  background-color: #E4E4E2;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: start;

  .question {
    margin-bottom: 16px;
  }

  .radio-label {
    display: inline-block;
    border-radius: 20px;
    cursor: pointer;
    width: 100px;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    padding: 8px;
    transition: all .3s ease;

    &.agree {
      border: 1px solid #62bd76;
      color: #62bd76 !important;
      margin-right: 20px;

      &.checked {
        color: #fff !important;
        background-color: #62bd76;
      }
    }

    &.disagree {
      border: 1px solid #fd5757;
      color: #fd5757 !important;

      &.checked {
        color: #fff !important;
        background-color: #fd5757;
      }
    }
  }

  .input-wrapper {
    margin: 0;

    [type="radio"] {
      display: none;
    }
  }

  .question-container {
    margin-bottom: 40px;

    .question {
      padding-left: 8px;
    }
  }




  .progress-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;

    .fraction {
      float: right;
      color: #00bcee !important;
      font-size: 18px !important;
    }

    .progressbar {
      margin-top: 10px;
      margin-bottom: 30px;
    }
  }

  button {
    background-color: #35bff4;
    border-radius: 12px;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    transition: all .2s ease;
    border: none;
    box-shadow: 0 6px 9px #0000001c;
    -moz-box-shadow: 0 6px 9px #0000001c;

  }
}

b span {
  font-size: 20px;

}


.survey-section-wrap {
  background-color: #fff;
  padding: 50px;
  border-radius: 1rem;
  margin: 20px;
  width: 1200px;
  min-width: 0;
  flex-shrink: 1;
}



.survey-section-wrap textarea {
  border-radius: 15px;
  width: 100%;
  padding: 0rem 1rem;
  outline: none;
  display: block;
  margin: 1rem 0rem;
  resize: none;
  border-color: #ccc;
  font-size: 15px;
  height: 55px;
  padding-top: 15px;
}

.survey-section-wrap .survey-section label {
  border-radius: 31px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  padding: 8px;

  width: 125px;
  display: inline-block;

}

.survey-section-wrap .button {
  display: flex;
  justify-content: space-between;
}

.survey-section-wrap .button button {
  color: #fff;
}

.survey-section-wrap .text-right button {
  display: flex;
  justify-content: space-between;
}

.survey-section-wrap i.fa-solid.fa-arrow-right {
  line-height: unset;
}


html body .survey-section .progress-wrap span {
  color: #00bcee !important;
  text-transform: capitalize;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

}

.survey-section .survey-section-wrap form span {

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  text-transform: capitalize;

  color: #171717;


  width: 100%;
  display: inline-block;




}

.survey-section-wrap br {
  display: none;
}

.survey-section-wrap .button .text-left i {
  padding-left: 0 !important;
}

.survey-section-wrap div#text-right button i {
  padding-right: 0 !important;
}

.survey-section-wrap .head-text {

  padding-bottom: 1rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
  color: #171717;
}


.survey-section-wrap button {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.survey-section-wrap button[disabled] {
  background: transparent;
  color: #35bff4;
  border-color: #35bff4;
  border-width: 1px;
  border-style: solid;
}


.label-quest {
  display: inline;
  line-height: 1;
}


.question .quest-in {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-align: justify;
  padding-left: 8px;
}

.n_vox {
  h1,h2,h3,h4,h5,h6,h7,p,label,input,legend {
  font-family: Inter !important;
  }
  h4 {
    color: #111827;
    
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.56px;
    margin-bottom: 0px;
  }
  p.sub_text {
    color: #35BFF4;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: -0.32px;
  }
  p.small_p {
color: rgba(0, 0, 0, 0.50);
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px
  }
  legend,label {
    color:#111827;
    font-feature-settings: 'salt' on;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    background: transparent;
    transform: unset !important;
    position: relative;
  }
  .MuiFormControl-root {
    background: transparent;
    box-shadow: none;
  }
  .MuiOutlinedInput-root {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    border: 1px solid #AFAFAF;
    background: #FFF;
    width: 463px;
}
.MuiOutlinedInput-notchedOutline
 {
   visibility: hidden;
 }
 .MuiOutlinedInput-input {
  padding: 12.5px 14px;
 }

.flex_basis_50 {
  display: flex;
  flex-basis: 50%;
  flex-direction: column;
}
.helper-text {
  margin-top: 10px;
  display: flex;
align-items: flex-start;
gap: 56px;
}
.lightblue_button {
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 6px;
background: #35BFF4;
border: unset;
color: white;
}
}