#myVoxContainer {
  width: 100%;
  height: auto;
}
.vox-content-container {
  margin-top: 120px;
}
.rbt-input-multi {
  cursor: text;
  overflow: hidden;
  position: relative;
  height: auto;
  width: auto;
}


.inactiveVoxClass{
  background: rgba(0,0,0,0.05);
  color:  #BBBBBB;
}

.add-vox-modal-header{
  background-color: #1FAAEC;
  color: white;
}
.add-vox-modal-header button{
  color: white;
}
.add-vox-modal-header button:hover{
  color: white;
}
.vox-date-picker{
  padding: 0 10px 0 10px !important;
}
.add-vox-modal-content .form-group{
  padding: 0 10px 0 10px !important;
}
.add-vox-modal-content .form-group label{
  color: #091726 !important;
  text-indent: 0px;
  margin-right: 5px;
}
.add-vox-modal-content .form-group input,select{
  width: 100% !important;
  height: 40px !important;
  box-shadow: 1px 2px 3px 0px #8080804f !important;
}
.add-vox-modal-content .form-group textarea{
  width: 100% !important;
  min-height: 100px !important;
  box-shadow: 1px 2px 3px 0px #8080804f !important;
}
.add-vox-modal-content .form-group input:hover , select:hover{
  width: 100%;
  height: 40px;
  box-shadow: none !important;
  border: 1px solid #1faaec !important;
}

.rbt-input-hint{
  visibility: hidden !important;
}