.nav-container-new {
  position: relative;
  width: 100%;
}
.navbar {
  width: 100%;
  height: 45px;
  position: fixed;
  top: 0;
  background-color: white !important;
  background-image: none;
  background-repeat: no-repeat;
  z-index: 1;
  box-shadow: 1px 1px 5px grey;
}
.hamburger-icon {
  color: black;
}
/* .navbar-dark .navbar-toggler {
    color: rgb(254 254 254) !important;
    border-color: rgb(255 255 255) !important;
  }
  
  .navbar-light .navbar-toggler-icon svg {
    color: white !important;
  } */
#dropdown-new {
  color: black;
  border: 2px solid black;
  border-radius: 5px;
  padding-left: 5px;
  z-index: 1011;
  font-size: 11px;
}



.language-select-container{
  max-width: 250px;
}

.language-select-container select{
  background-color: white;
  border-radius: 5px;
  padding-left: 10px;
}