@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700,900");

body,
html {
  color: #333538;
  font-family: "Lato", sans-serif;
  line-height: 1.6;
  padding: 0;
  margin: 0;
}

a {
  color: #f27173;
  text-decoration: none;
}

a:hover {
  color: #e25f5f;
  text-decoration: underline;
}

.content {
  max-width: 800px;
  margin: auto;
  padding: 16px 32px;
}

.header {
  text-align: center;
  padding: 32px 0;
}
.graph-btns {
  display: flex;
  align-items: center;
  justify-content: center;
}
.graph-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrapper {
  width: 100%;
  margin: 2rem;
  min-height: 250px;
  padding: 3rem 0;
  position: relative;
}

.wrapper.col-2 {
  display: inline-block;
  min-height: 256px;
  width: 49%;
}

@media (max-width: 400px) {
  .wrapper.col-2 {
    width: 100%;
  }
}

.wrapper canvas {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.toolbar {
  display: flex;
}

.toolbar > * {
  margin: 0 8px 0 0;
}

.btn {
  background-color: #aaa;
  border-radius: 4px;
  color: white;
  padding: 0.25rem 0.75rem;
}

.btn .fa {
  font-size: 1rem;
}

.btn:hover {
  background-color: #888;
  color: white;
  text-decoration: none;
}

.btn-outline-primary-updated:hover {
  background-color: #1faaec;
  color: #fff;
}

.btn-chartjs {
  background-color: #f27173;
}

.btn-chartjs:hover {
  background-color: #e25f5f;
}

.btn-docs:hover {
  background-color: #2793db;
}

.btn-docs {
  background-color: #36a2eb;
}

.btn-docs:hover {
  background-color: #2793db;
}

.btn-gh {
  background-color: #444;
}

.btn-gh:hover {
  background-color: #333;
}

.btn-on {
  border-style: inset;
}

.chartjs-title {
  font-size: 2rem;
  font-weight: 600;
  white-space: nowrap;
}

.chartjs-title::before {
  /*background-image: url(logo.svg);*/
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 40px;
  content: "Chart.js | ";
  color: #f27173;
  font-weight: 600;
  padding-left: 48px;
}

.chartjs-caption {
  font-size: 1.2rem;
}

.chartjs-links {
  display: flex;
  justify-content: center;
  padding: 8px 0;
}

.chartjs-links a {
  align-items: center;
  display: flex;
  font-size: 0.9rem;
  margin: 0.2rem;
}

.chartjs-links .fa:before {
  margin-right: 0.5em;
}

.samples-category {
  display: inline-block;
  margin-bottom: 32px;
  vertical-align: top;
  width: 25%;
}

.samples-category > .title {
  color: #aaa;
  font-weight: 300;
  font-size: 1.5rem;
}

.samples-category:hover > .title {
  color: black;
}

.samples-category > .items {
  padding: 8px 0;
}

.samples-entry {
  padding: 0 0 4px 0;
}

.samples-entry > .title {
  font-weight: 700;
}

@media (max-width: 640px) {
  .samples-category {
    width: 33%;
  }
}

@media (max-width: 512px) {
  .samples-category {
    width: 50%;
  }
}

@media (max-width: 420px) {
  .chartjs-caption {
    font-size: 1.05rem;
  }

  .chartjs-title::before {
    content: "";
  }

  .chartjs-links a {
    flex-direction: column;
  }

  .chartjs-links .fa {
    margin: 0;
  }

  .samples-category {
    width: 100%;
  }
}

.analyser table {
  color: #333;
  font-size: 0.9rem;
  margin: 8px 0;
  width: 100%;
}

.analyser th {
  background-color: #f0f0f0;
  padding: 2px;
}

.analyser td {
  padding: 2px;
  text-align: center;
}

.btn-table-actions {
  font-size: 14px;
  font-weight: 400;
  padding: 10px;
}

.btn-table-actions-disabled {
  user-select: none;
  pointer-events: none;
}

.btn-link {
  font-weight: 400;
  color: #35bff4;
  text-decoration: none;
  background-color: transparent;
}

.btn-link:hover {
  background-color: transparent;
  color: #35bff4;
  text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
  box-shadow: none;
  text-decoration: none;
}

