#text-title-funkiness {
    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #20ABEC;
}

#text-title-nudge {
    font-family: 'Dosis', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    color: #20ABEC;
}


.disabled.btn-primary{
    background:#CCCCCC;
}

.q-pack-container{
    width: 100%;
    height: auto;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    
}


.custom-pack-content-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 2rem;
}
.frunkiness-slider-container{
    display: flex;
    align-items: center;
    justify-content: center;
}
.frunkiness-slider-container p{
    margin: 0 1rem 0 1rem;

}

.frunkiness-slider-right{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
}

.frunkiness-iten{
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.skillset_container{
    padding: 2rem;
}