.top-buffer {
  margin-top: 20px;
}

.video-react {
  border-radius: 8px !important;
}

.video-react.video-react-fluid {
  border-radius: 8px;
}

a .guide-links {
  text-decoration: none !important;
}

/*div .pdf-placeholder {*/
/*display: inline-block;*/
/*content: 'PDF';*/
/*border: 1px solid #20ABEC !important;*/
/*border-radius: 8px;*/
/*padding: 1.25rem 1.25rem 1.25rem 1.25rem;*/
/*}*/

/*.guide-item-date{*/
/*line-height: 1;*/
/*margin-left: 10px;*/
/*font-size: 14px;*/
/*color: rgba(73, 73, 73, 0.6);*/
/*}*/

.pdf-placeholder {
  width: 56px;
  height: 56px;
  border: 1px solid #1faaec;
  background-color: #1faaec;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 8px;
  color: white;
}

.guide-item-title {
  font-size: 16px !important;
  line-height: 24px;
  color: white !important;
  text-indent: 0;
}

.guide-item-date {
  font-size: 14px;
  line-height: 24px;
  color: white;
}

.video-pagination {
  line-height: 4;
  margin-left: 720px;
}

.pdf-pagination {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.top-buffer {
  background-color: #2f242c;
  border: 1px solid #2f242c;
  /* margin-right: 3rem; */
  border-radius: 7px;
  padding: 7px;
  transition: 0.3s all ease-in;
}

.top-buffer:hover {
  transform: translateY(-10px);
}


.settings-container{
  max-width: 100%;
  /*height: 400px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}