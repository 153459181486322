.packagesTabs {
  max-width: 100%;
  justify-content: center;
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-bottom: 20px;
}

.campaignTabs {
  margin: 20px auto;
  max-width: 80%;
  display: flex;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  /* border: 1px solid lightgray !important; */
  border-radius: 5px !important;
  overflow-x: hidden;
  overflow-y: hidden;
}

.campaignTabs a {
  width: 50% !important;
  border-bottom: 1px solid lightgray !important;
}
.campaignTabs .active {
  width: 50% !important;
  background-color: transparent !important;
  color: #1faaec !important;
}

/* .campaignTabs #campaignTabs-tab-questions {
  width: 50% !important;
  border-bottom: 1px solid lightgray !important;
} */
/* #campaignTabs #tabs {
  background: #007b5e;
  color: #eee;
}
#campaignTabs #tabs h6.section-title {
  color: #eee;
} */

/* #campaignTabs-tab-agenda {
  margin-right: 40px;
  background-color: transparent !important;
  padding-left: 40px;
  padding-right: 40px;
  border-bottom: 1px solid rgba(32, 171, 236, 0.2);
}

#campaignTabs-tab-agenda.active {
  border-bottom: 1px solid #20abec;
} */

/* #campaignTabs-tab-questions {
  margin-left: 40px;
  background-color: transparent !important;
  border-bottom: 1px solid rgba(32, 171, 236, 0.2);
  padding-left: 40px;
  padding-right: 40px;
} */

/* #campaignTabs-tab-questions.active {
  border-bottom: 1px solid #20abec;
} */

.packagesTabs #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link {
  text-align: center;
  width: 33%;
  color: #091726;
  background-color: transparent;
  border-color: transparent transparent transparent;
  text-decoration: none;
  font-size: 16px;
  padding-left: 1%;
  padding-right: 1%;
}

.packagesTabs #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link:hover {
  width: 33%;
  color: #20abec;
  background-color: transparent;
  border-color: transparent transparent transparent;
  text-decoration: none;
  font-size: 16px;
  padding-left: 1%;
  padding-right: 1%;
}

.packagesTabs #tabs .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  width: 34%;
  color: white;
  background-color: #1faaec;
  border-color: transparent transparent transparent;
  text-decoration: none;
  font-weight: normal;
  padding-left: 1%;
  padding-right: 1%;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

/* .nav-tabs {
  border: none;
}

.nav-tabs .nav-link {
  text-decoration: none;
}

#campaignTabs .nav-link {
  color: #0062cc;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
}
#campaignTabs .nav-link:hover {
  border: none;
}
#campaignTabs thead {
  background: #f3f3f3;
  color: #333;
}
#campaignTabs a {
  text-decoration: none;
  color: #333;
  font-weight: 600;
} */

.DayPicker {
  font-family: "Work", sans-serif;
  border: 1px solid #20abec;
  border-radius: 8px;
}

.DayPicker-NavButton--prev {
  margin-right: 13em;
}

.DayPicker-Caption {
  text-align: center;
}

.DayPicker-Weekday abbr[title] {
  color: #20abec;
  font-weight: bold;
}
