@import url("https://fonts.googleapis.com/css?family=Dosis&display=swap");

#homeContainer {
  width: 100%;
  height: auto;
  padding: 5%;
  /*display: flex;*/
  align-items: center;
  justify-content: center;
}

.left-side {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0 20px 0 20px;
}
.left-side .flex-container__vox {
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 0 20px 0 20px; */
}
#yes-container {
  font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  width: 150px;
  height: 150px;
  position: relative;
  float: left;
  /* margin-left: 100px; */
}
#burst-12-shape {
  background: linear-gradient(180deg, #20abec 0%, #35bff4 100%);
  width: 150px;
  height: 150px;
  position: relative;
  text-align: center;
  box-sizing: border-box;
  border-radius: 5px;
}
#burst-12-shape:before,
#burst-12-shape:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  width: 150px;
  background: linear-gradient(180deg, #20abec 0%, #35bff4 100%);
  box-sizing: border-box;
  border-radius: 5px;
}

#burst-12-shape:before {
  transform: rotate(30deg);
}
#burst-12-shape:after {
  transform: rotate(60deg);
}

#yes-percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 46px;
  color: white;
}
#question-container {
  font-family: "Dosis", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  /* width: 400px; */
  font-size: 2.7vw;
  float: left;
  margin-left: 60px;
  transform: translate(0%, -10%);
}

#text-title-did-you-know {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 4vw;
  line-height: 121px;
  color: #20abec;
}

