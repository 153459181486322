.split {
  min-height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  overflow-x: hidden;
  padding-top: 20px;
}

.left {
  left: 0;
  background-color: #0a1727;
  color: #fff;
}
.buttonShowPassword {
  position: absolute; z-index: 100; margin-top: 37px; text-align: right; margin-left: -42px; background: transparent; border: none;
}
.n_vox.limit_input .MuiOutlinedInput-root{
  width: unset;
}
.bulletlist-register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  background: #0A1727;
  color: #FFF;
  font-size: 14px;
  min-height: 100px;
  max-width: 500px;
  margin: 15px auto;
  padding: 15px unset !important;
  padding-bottom: 10px;
  padding-top: 10px;
  padding-right: 12px;
  border-radius: 4px;
}

.bulletlist-title {
  font-size: 20px;
  margin-bottom: 5px;
  margin-right: 30px;
  justify-self: center;
  text-align: center;
}

.right {
  right: 0;
  background-color: #fff;
  color: black;
}

.right .col-md-8 {
  max-width: unset !important;
  text-align: left !important;
}

.logo-right {
  display: unset;
  max-width: 200px;
  margin-top: 150px;
}

.terms-center {
  text-align: unset !important;
}

.right a {
  color: #fff;
}
.terms-right::before {
  left: 0rem;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.n_vox .form-field,
.n_vox .step_one.limited {
  width: 463px;
  max-width: 80%;
}
.card_fieldset {
  margin-bottom: 20px;
  background: #fff;
  box-shadow: 0 1px 3px 0 rgba(50, 50, 93, 0.15),
    0 4px 6px 0 rgba(112, 157, 199, 0.15);
  border-radius: 4px;
  border: none;
  font-size: 0;
  padding: 15px;
}

.actionButton {
  background: transparent;
  outline: none;
  border: none;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  padding: 0;
  font-size: 24px;
}
.customContainer {
  margin-left: 16% !important;
}

.customContainer .helper-text li,
.customContainer .helper-text p,
.customContainer .helper-text a
{
color: #131313;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 20px;
}
.lightblue_button {
  width: 487px;
height: 48px;
padding: 8px 20px;
max-width: 80%;
}
.small_gray_helper {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.50);
}
.
@media screen and (max-width: 1168px) {
  .right .col-md-8 {
    margin-left: auto !important;
    text-align: center !important;
  }
  .logo-right {
    margin-top: 0px;
  }
  .n_vox .MuiOutlinedInput-root {
    max-width: 80%;
    margin: auto;
  }
  .n_vox .form-field, .n_vox .step_one.limited,
  .n_vox .lightblue_button
  {
    margin: auto;
  }
}


@media (min-width: 30em) {
  .terms-right::before {
    left: -1.5rem;
  }
}

@media (min-width: 700px) {
  .terms-right::before {
    left: 0rem;
  }
}

@media (min-width: 790px) {
  .terms-right::before {
    left: -1.5rem;
  }
}

@media (min-width: 50em) {
  .bulletlist-register {
    width: 470px;
    max-width: unset;
  }
  .logo-right {
    max-width: 200px;
  }
  .terms-right::before {
    left: -1.5rem;
  }
}

@media (min-width: 70em) {
  .split {
    width: 50%;
  }
  .left {
    display: flex;
  }

  .logo-right {
    display: none;
  }
}
@media (min-width: 1120px) {
  .n_vox .form-field,
  .n_vox .lightblue_button
  {
    max-width: unset;
  }
}
@media (max-width: 1120px) {
  .n_vox {
    /* .customContainer {
      margin-left: auto !important;
    } */
.helper-text li{
  list-style-type: none
  }
.helper-text  {
  grid-gap: unset;
  gap: unset;
}
/* .step_one.limited {
max-width: 100%;
}
.center_on_mobile {
  text-align: center;
} */
}
}