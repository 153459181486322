*, *:after, *:before{-moz-box-sizing:border-box; -webkit-box-sizing:border-box; box-sizing:border-box}


.modalLoadingQuestionPackBody {
    background: #20abec;
    font-family: arial;
    margin: 0;
    padding: 0;
}


.modalLoadingQuestionPackBody p {
    color:#FFF;
    font-size: 24px;
    margin-left:auto;
    margin-right:auto;
    text-align:center;
    padding:10px;
    width:80%;
}

/* artboard container */
.modalLoadingQuestionPack .container{padding:10% 0 0; width:12em; margin:0 auto}

.modalLoadingQuestionPack .container *{border:2px solid #fff;}

/* main container */
.modalLoadingQuestionPack .main{
    height:10em;
    padding:.5em;
    position:relative;
    width:8.625em;
}

/* Main Areas 2(top) & 3(bottom) */
.modalLoadingQuestionPack .m2, .m3{
    position:relative;
    width:100%;
}


.modalLoadingQuestionPack .m2{
    border-bottom:0;
    height:53%
}


/* Small Circles */
.modalLoadingQuestionPack .mini-Circle.mcBig{
    border-radius:300px;
    height:.725em;
    margin:.5em auto;
    width:.725em
}

.modalLoadingQuestionPack .mc1, .mc2{
    position:absolute;
    top:4%;
}

.modalLoadingQuestionPack .mc1{left:-1em}
.modalLoadingQuestionPack .mc2{right:-1em}
.modalLoadingQuestionPack .mcBig{
    bottom:-.66em;
    position:absolute;
    right:-1.2em;
}

/* Boxes */
.modalLoadingQuestionPack .rec{
    bottom:.2em;
    height:1.875em;
    left:32.5%;
    position:absolute;
    width:2.5em;
}

.modalLoadingQuestionPack .sRec{
    bottom:.2em;
    left:.3em;
    height:.55em;
    position:absolute;
    width:1em;
}

/* Tape Lines */
.modalLoadingQuestionPack .line{
    background:#fff;
    border:0;
    height:.1875em;
    position:absolute;
}

.modalLoadingQuestionPack .line-Left{
    left:-2.05em;
    -moz-transform:rotate(40deg);
    -webkit-transform:rotate(40deg);
    transform:rotate(40deg);
    top:.4em;
    width:1.3em
}

.modalLoadingQuestionPack .line-Left2{
    left:-.4em;
    top:.76em;
    width:.4em;
}

.modalLoadingQuestionPack .line-Right{
    right:-.64em;
    top:1.25em;
    -moz-transform:rotate(-32deg);
    -webkit-transform:rotate(-32deg);
    transform:rotate(-32deg);
    width:.6em;
}

.modalLoadingQuestionPack .line-Right2{
    right:-1.66em;
    -moz-transform:rotate(-40deg);
    -webkit-transform:rotate(-40deg);
    transform:rotate(-40deg);
    top:.25em;
    width:1em
}


.modalLoadingQuestionPack .m3{
    height:47%;
}

.modalLoadingQuestionPack .circle{
    background:#20abec;
    border-radius:500px;
    height:5.25em;
    position:absolute;
    top:-1.926em;
    width:5.25em;
    z-index:3;

    -moz-animation:rotate 5s infinite linear;
    -webkit-animation:rotate 5s infinite linear;
    animation:rotate 5s infinite linear

}


@-moz-keyframes rotate{
    from {
        -moz-transform:rotate(0deg);
        -webkit-transform:rotate(0deg);
        transform:rotate(0deg)
    }

    to {
        -moz-transform:rotate(360deg);
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@-webkit-keyframes rotate{
    from {
        -moz-transform:rotate(0deg);
        -webkit-transform:rotate(0deg);
        transform:rotate(0deg)
    }

    to {
        -moz-transform:rotate(360deg);
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}
@keyframes rotate{
    from {
        -moz-transform:rotate(0deg);
        -webkit-transform:rotate(0deg);
        transform:rotate(0deg)
    }

    to {
        -moz-transform:rotate(360deg);
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}


.modalLoadingQuestionPack .c1{
    left:-1.625em;

}

.modalLoadingQuestionPack .c2{
    right:-1.625em;
}

.modalLoadingQuestionPack .inner-C{
    border-radius:400px;
    height:29%;
    left:35%;
    position:absolute;
    top:35%;
    width:29%;
}

.modalLoadingQuestionPack .inner-inner{
    border-radius:300px;
    height:36%;
    margin:33% auto;
    width:36%
}

.modalLoadingQuestionPack .hole{
    border:0;
    height:1.175em;
    position:absolute;
    width:.8em;
}

.modalLoadingQuestionPack .h-Top, .h-Bottom{
    height:.123em;
}
.modalLoadingQuestionPack .h-Top{
    left:0;
    top:0;
    width:100%;
}
.modalLoadingQuestionPack .h-Bottom{
    bottom:0;
    left:16%;
    width:72%
}
.modalLoadingQuestionPack .h-Left, .h-Right{
    height:100%;
    top:0;
    width:.123em;
}
.modalLoadingQuestionPack .h-Left{
    left:0;
    -moz-transform:rotate(-10deg);
    -webkit-transform:rotate(-10deg);
    transform:rotate(-10deg)
}
.modalLoadingQuestionPack .h-Right{
    right:0;
    -moz-transform:rotate(10deg);
    -webkit-transform:rotate(10deg);
    transform:rotate(10deg)
}

.modalLoadingQuestionPack .hole1{
    left:.2em;
    top:-1.6em;
}

.modalLoadingQuestionPack .hole2{
    right:1.5em;
    top:.9em;
    -moz-transform:rotate(-127deg);
    -webkit-transform:rotate(-127deg);
    transform:rotate(-127deg);
}

.modalLoadingQuestionPack .hole3{
    left:1.5em;
    top:.9em;
    -moz-transform:rotate(127deg);
    -webkit-transform:rotate(127deg);
    transform:rotate(127deg);
}

.modalLoadingQuestionPack .nobs{
    border:0;
    float:left;
    width:23%
}

.modalLoadingQuestionPack .mini-Circle{
    border-radius:300px;
    height:.625em;
    margin:.5em auto;
    width:.625em
}

.modalLoadingQuestionPack .boxes{
    border:0;
    float:left;
    padding-top:.25em;
    width:27%;
}

.modalLoadingQuestionPack .box{
    height:1em;
    margin:.5em 0;
    overflow:hidden;
    position:relative;
}

.modalLoadingQuestionPack .boxes1 .box{
    margin-right:.2em;
}

.modalLoadingQuestionPack .boxes2 .box{
    margin-left:.2em;
}

.modalLoadingQuestionPack .meter{
    background:#fff;
    border:0;
    bottom:-.19em;
    height:.6em;
    left:45%;
    position:absolute;
    width:.1875em;
}

.modalLoadingQuestionPack .meter1{
    -moz-animation:bf .4s infinite linear alternate;
    -webkit-animation:bf .4s infinite linear alternate;
    animation:bf .4s infinite linear alternate;
}
.modalLoadingQuestionPack .meter2{
    -moz-animation:fb .6s infinite alternate;
    -webkit-animation:fb .6s infinite alternate;
    animation:fb .6s infinite alternate;
}
.modalLoadingQuestionPack .meter3{
    -moz-animation:fb 1.1s infinite alternate;
    -webkit-animation:fb 1.1s infinite alternate;
    animation:fb 1.1s infinite alternate;
}
.modalLoadingQuestionPack .meter4{
    -moz-animation:bf .88s infinite linear alternate;
    -webkit-animation:bf .88s infinite linear alternate;
    animation:bf .88s infinite linear alternate;
}

@-moz-keyframes bf {
    from {
        left:25%;
        -moz-transform:rotate(-60deg);
        -webkit-transform:rotate(-60deg);
        -webkit-transform:rotate(-60deg);
    }

    to {
        left:65%;
        -moz-transform:rotate(60deg);
        -webkit-transform:rotate(60deg);
        transform:rotate(60deg);
    }
}
@-webkit-keyframes bf {
    from {
        left:25%;
        -moz-transform:rotate(-60deg);
        -webkit-transform:rotate(-60deg);
        -webkit-transform:rotate(-60deg);
    }

    to {
        left:65%;
        -moz-transform:rotate(60deg);
        -webkit-transform:rotate(60deg);
        transform:rotate(60deg);
    }
}
@keyframes bf {
    from {
        left:25%;
        -moz-transform:rotate(-60deg);
        -webkit-transform:rotate(-60deg);
        -webkit-transform:rotate(-60deg);
    }

    to {
        left:65%;
        -moz-transform:rotate(60deg);
        -webkit-transform:rotate(60deg);
        transform:rotate(60deg);
    }
}

@-moz-keyframes fb {
    from {
        left:65%;
        -moz-transform:rotate(60deg);
        -webkit-transform:rotate(60deg);
        transform:rotate(60deg);
    }

    to {
        left:25%;
        -moz-transform:rotate(-60deg);
        -webkit-transform:rotate(-60deg);
        transform:rotate(-60deg);
    }
}
@-webkit-keyframes fb {
    from {
        left:65%;
        -moz-transform:rotate(60deg);
        -webkit-transform:rotate(60deg);
        transform:rotate(60deg);
    }

    to {
        left:25%;
        -moz-transform:rotate(-60deg);
        -webkit-transform:rotate(-60deg);
        transform:rotate(-60deg);
    }
}
@keyframes fb {
    from {
        left:65%;
        -moz-transform:rotate(60deg);
        -webkit-transform:rotate(60deg);
        transform:rotate(60deg);
    }

    to {
        left:25%;
        -moz-transform:rotate(-60deg);
        -webkit-transform:rotate(-60deg);
        transform:rotate(-60deg);
    }
}