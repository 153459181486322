.InputFromTo
  .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}
.InputFromTo .DayPicker-Day {
  border-radius: 0 !important;
}
.InputFromTo .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.InputFromTo .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.InputFromTo .DayPickerInput-Overlay {
  width: 550px;
}
.InputFromTo-to .DayPickerInput-Overlay {
  margin-left: -198px;
}

.InputFromTo input {
  width: 100px;
}

.data-table-wrapper .btn {
  margin-bottom: 5px;
  background: linear-gradient(279.09deg, #20abec 0%, #35bff4 100%);
  border: none;
}

.data-table-wrapper .btn.disabled {
  background: #909395;
}
.btn-outline-primary,
.data-table-wrapper .btn-outline-primary {
  color: #35bff4;
  background: transparent;
  border: 1px solid #35bff4;
}

.table {
  color: #494949;
}

.table th {
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
}

.table td {
  padding: 0.75rem 1.5rem 0.75rem 1.5rem;
}

.table-bordered {
  border-style: hidden;
  border-radius: 4px;
  box-shadow: 0 0 0 1px #20abec;
}

.table tr td:first-child {
  padding: 0.75rem 4rem 0.75rem 1.5rem;
}

.table tr:nth-child(odd) td {
  background-color: rgba(32, 171, 236, 0.1) !important;
}

.page-item.active .page-link {
  background-color: #20abec !important;
  border-color: #20abec !important;
}

.table-bordered th {
  border: none !important;
}
