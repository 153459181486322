.side-bar-parent {
  position: relative;
  overflow: hidden;
}
#sidebar-menu {
  position: fixed;
  width: 17%;
  height: 100vh;
  overflow-y: scroll;
  background-color: #091726 !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  z-index: 999;
}
.logo-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}
.logo-container img {
  max-width: 70%;
  height: auto;
}
.side-nav-container {
  width: 100%;
}
.navbar-text {
  display: block !important;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 100% !important;
}
.nav .normal {
  width: 100%;
  height: 45px;
  margin-bottom: 10px;
  padding-left: 15px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.nav .normal i {
  margin-right: 10px;
}

.nav .selected {
  background-color: #1faaec;
  font-weight: 700;
  color: white;
}

.admin-title {
  width: 100%;
  height: 45px;
  margin-top: 10px;
  margin-bottom: 10px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  background-color: #1faaec1f;
}
.admin-title p {
  margin: 0;
}


.disabled-link {
  color: grey !important;
}


/* 

#sidebar-menu a {
  width: 100% !important;
  min-width: 110px;
  text-decoration: none;
}

#sidebar-menu a:hover {
  font-weight: bold;
} */
