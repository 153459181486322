.n_vox {



  h1,h2,h3,h4,h5,h6,h7,p,label,input,legend {
    font-family: Inter !important;
  }
  .text-end-register {
    text-align: end;
    a {
      color: #35BFF4;
      font-weight: bolder;
    }
  }
  .flexify_center_gap { 
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 46px;
    margin-bottom: 29px;
  }
  .monthly_annual {
    padding: 6px;
    display: flex;
    gap: 8px;
    border-radius: 8px;
border: 1px solid var(--gray-100, #F2F4F7);
background: #F9FAFB;
  button {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    color: #667085;
    border: unset;
    border-radius: 6px;
    padding: 10px 14px;
    border-color: unset;
    background: transparent;
  }
  button.active {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
  }
  }
  .plans_page {
    max-width: 90%;
    display: flex;
    margin: auto;
    max-width: 1300px;

    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .custom_card {
      border-radius: 16px;
      border: 1px solid rgba(0, 0, 0, 0.10);
      background: #FFF;
      padding: 12px 14px;
      min-height: 75vh;

      h6 {
        color: #545454;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        min-height: 18px;
        max-height: 18px;
      }

      h4 {
        margin-bottom: 4px;
        min-height: 18px;
        font-size: 18px;
        display: flex;
      }

      p {
        color: #545454;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
        display: flex;
        gap: 10px;
      }

      h5 {
        color: #4A4949;
        font-family: Inter;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 60px;
        span {
color: #667085;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    position: relative;
    margin-left: 5px;
    vertical-align: top;
        }
      }
      button {
        border-radius: 6px;
        background: #35BFF4;
        display: flex;
        width: 231px;
        padding: 8px 0px;
        justify-content: center;
        border: unset;
        color: white;
      }
      .green_button button {
        background: #92d050;
      }
    }


  }

  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1168px) {
  .n_vox {
  .plans_page {
  flex-direction: column;
  gap: 20px;
  text-align: center;
  max-width: 600px;
  .custom_card {
    min-height: unset !important;
    button,p,h4 {
    margin-left: auto;
    margin-right: auto;
   }
    p {
      margin-left: 10%;
      text-align: left;
    }
  }
}

}
}