.container.payment {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.remove_chat #crisp-chatbox {
  display: none !important;
}

.n_vox {
  &.payment_page { 
  position: relative;
  z-index: 1000;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  h7,
  p,
  label,
  input,
  legend {
    font-family: Inter !important;
  }
  .undo_mui .MuiOutlinedInput-root {
    input::placeholder {
      color: black;
    }
    border: inherit !important;
    color: inherit !important;
  }
  .split.left {
    background: white;
    color: black;
  }
  
  .minheight44 {
    min-height: 44px;
  }

  .split.left .ricard {
    width: 517px;
    max-width: 90%;
    margin: auto;
    // height: 584px;
    flex-shrink: 0;
    border-radius: 9px;
    border: 1px solid rgba(0, 0, 0, 0.20);

  }
  


  .ricard {
    padding: 34px 16px;
    input {
    padding: 12px 14px 12px 10px;
    align-items: center;
    grid-gap: 8px;
    gap: 8px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border: 1px solid #D0D5DD;
    background: #FFF;
    margin-top: 6px;
  }
    p, label {
      font-weight: 600;
      text-align: left;
    }
  }

  .flexify_center_gap {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    margin-top: 46px;
    margin-bottom: 29px;
  }

  .monthly_annual {
    padding: 6px;
    display: flex;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid var(--gray-100, #F2F4F7);
    background: #F9FAFB;

    button {
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      color: #667085;
      border: unset;
      border-radius: 6px;
      padding: 10px 14px;
      border-color: unset;
      background: transparent;
    }

    button.active {
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10);
    }
  }

  .container.payment {
    max-width: 90%;
    display: flex;
    margin: auto;
    max-width: 1300px;


    h2 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .MuiSwitch-root {
      // position: unset !important; 
    }
    .payment_form {
      label {
        text-align: left;
        margin-bottom: 15px;
      }
      input::placeholder {
        color: #AFAFAF;
      }
      input {
      display: flex;
      }
      input, 
      select,
      .StripeElement
       {
        padding: 12px 14px 12px 10px;
        align-items: center;
        grid-gap: 8px;
        gap: 8px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        background: #FFF;
        margin-top: 6px;
      }
      input, button, label, p {
        width: 463px !important;
        max-width: 100%;
      }
      input:disabled {
        background: rgb(239, 239, 239) !important;
      }
      button {
        background-color: #35BFF4;
        font-size: 16px;
        border: unset;
        padding: 4px 24px;
        border-radius: 9px;
        color: white;
      }
    }
  }

  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1230px) {
  .payment_page {
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }

  .custom_card {

    button,
    p {
      margin-left: auto;
      margin-right: auto;
    }
  }
}
@media (max-width: 1120px) {
    .n_vox.payment_page .split.left,
    .n_vox.payment_page .split.right {
      .centered {
        position: unset;
        top: unset;
        left: unset;
        transform: unset;
        text-align: unset;
      }
      position: relative;
      width: 100%;
      height: 100%;
      .spaced-between.dif-space {
        justify-content: space-evenly !important;
      }
    }
}