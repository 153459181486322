.progressbar {
  height: 8px;
  width: 100%;
  border-radius: 40px;
  background-color: rgb(226, 226, 226);
}
.progressbar-meter {
  height: 100%;
  border-radius: 40px;
  background-color: rgb(0, 188, 238);
  text-align: right;
}
