.charts-pie-containet {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.divCharts {
  text-align: center;
  display: flex;
}

.divCharts-logo {
  height: 40vmin;
}

.divCharts-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.divCharts-link {
  color: #09d3ac;
}
