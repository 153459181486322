html {
  height: 100%;
}

body {
  /*background-image:*/
  /*url("data:image/svg+xml,%3Csvg width='213' height='288' viewBox='0 0 213 288' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M152.003 -40.2984C175.426 -14.8261 179.347 29.0331 190.281 69.0993C201.215 109.166 219.161 145.439 209.278 171.686C199.395 197.932 161.683 214.152 129.287 235.974C96.8908 257.796 69.811 285.219 40.8586 287.796C11.9062 290.373 -18.9187 268.105 -46.0706 244.579C-73.2226 221.054 -96.7017 196.271 -103.663 167.788C-110.625 139.305 -101.069 107.121 -87.6735 79.3653C-74.2775 51.6096 -57.0411 28.2822 -34.6669 2.46918C-12.2927 -23.3439 15.2192 -51.6427 50.4374 -62.2494C85.6556 -72.8561 128.58 -65.7707 152.003 -40.2984Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='53.0738' y1='-67' x2='53.0738' y2='288' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2320ABEC'/%3E%3Cstop offset='1' stop-color='%2335BFF4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E"),*/
  /*url("data:image/svg+xml,%3Csvg width='320' height='280' viewBox='0 0 320 280' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M51.7895 402.261C21.8312 381.495 16.7746 325.455 10.2836 276.679C3.79253 227.904 -4.13295 186.392 2.50985 144.567C9.15265 102.742 30.3637 60.6036 64.2847 33.6252C98.2058 6.64679 144.837 -5.1712 186.178 2.10997C227.518 9.39113 263.569 35.7714 300.185 61.8743C336.801 87.9771 373.981 113.802 393.364 150.767C412.747 187.732 414.332 235.835 405.146 285.486C395.96 335.138 376.004 386.337 338.596 404.682C301.188 423.027 246.328 408.519 191.468 408.519C136.608 408.519 81.7477 423.027 51.7895 402.261Z' fill='url(%23paint0_linear)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear' x1='205.283' y1='413.965' x2='205.283' y2='1.25712e-05' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%2320ABEC'/%3E%3Cstop offset='1' stop-color='%2335BFF4'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");*/
  /* background-repeat: no-repeat, no-repeat;
  background-size: 10% auto; */
  /* background-position: top -80px left -30px, bottom -35% right; */
}

.text-signin {
  font-family: "Dosis", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 96px;
  /* line-height: 121px; */
  text-align: center;
  color: white !important;
  margin-top: 0 !important;
}

/* .login-field {
  margin: auto;
  width: 400px;
  height: 64px;

  background: #ffffff;
  border: 1px solid #20abec;
  box-sizing: border-box;
  border-radius: 89px;

  font-family: "Work", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;

  color: #494949;
} */

/* .btn-login {
  width: 400px;
  height: 64px;
  left: 768px;
  top: 576px;

  background: linear-gradient(279.09deg, #20abec 0%, #35bff4 100%);
  border-radius: 98px;
  border: none;

  font-family: "Work", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 28px;
  text-align: center;

  color: #ffffff;
} */

a {
  color: #494949;
  text-decoration: underline;
}

a:hover,
a:focus {
  color: #000000;
}

.section-left {
  width: 100%;
  height: 100vh;
  background-color: #0a1727;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.section-left h2 {
  text-align: center;
  margin-top: 10px;
  width: 70%;
}
.section-left p {
  text-align: center;
  margin-top: 10px;
  width: 70%;
}

.flex-imgs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.flex-imgs img {
  max-width: 30%;
  height: auto;
}
.superman {
  margin-right: -70px;
}

.machine {
  transform: translateY(40px);
}
.section-right {
  width: 100%;
  height: 100vh;
  padding: 0;
  background-color: #3d9ce0;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card_fieldset {
  width: 100%;
}
