@import url("https://fonts.googleapis.com/css?family=Work+Sans&display=swap");


@media only screen and (max-width: 600px) {
  html {
    zoom: 80%;
  }
}


body {
  margin: 0;
  font-family: "Work", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none !important;
}

/* .btn-primary {
  background: linear-gradient(279.09deg, #20abec 0%, #35bff4 100%);
  border: none !important;
} */
.btn-primary {
  border: none !important;
  background-color: #1faaec !important;
}

.search {
  border-radius: 500px !important;
  border-color: #494949 !important;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

label {
  color: #555 !important;
}

label input {
  width: 100% !important;
}


#crisp-chatbox{
  z-index:10;
}

.transition-500 {
  transition: all 500ms ease;
}

.rotate-180 {
  transform: rotate(180deg);
}

.modal-header-update {
  align-items: center;
  background-color: #1faaec;
  color: #fff;
}

.modal-header-update .close {
  opacity: 1;
  color: #fff;
  font-size: 32px;
}

.modal-header-request {
  position: relative;
}

.modal-header-request .close {
  position: absolute;
  top: 20px;
  right: 20px;
}

.modal-header-update .close:hover {
  color: #fff;
}

.pulse-question-close {
  background-color: transparent;
  border: 0;
  outline: 0;
}

.form-check-update {
  display: flex !important;
  align-items: center;
}

.form-check-update input {
  display: relative;
  margin-top: 0;
  margin-right: 8px;
}

.modal-header-scan .close {
  position: absolute;
  right: 1.5rem;
}

.add-new-pulse-select {
  height: calc(1.5em + 1rem + 2px) !important;
  box-shadow: none !important;

  background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
  background-position: calc(100% - 0.75rem) center !important;
  -moz-appearance:none !important;
  -webkit-appearance: none !important; 
  appearance: none !important;
  padding-right: 2rem !important;
}

.no-shrink {
  flex-shrink: 0;
}

.bg-primary-light {
  background-color: rgba(32, 171, 236, 0.1) !important;
}

#CanUsersModerate {
  width: 16px;
  height: 16px;
}

.request-link {
  color: #35bff4;
  text-decoration: underline !important;
}

.request-link:hover, .request-link:active, .request-link:focus {
  color: #35bff4;
}

table.table-no-border th,
table.table-no-border td {
  border: 0 !important;
}

table.questionsAnswerTable {
  border-collapse: collapse;
  width: 100% !important;
  display: flex;
  flex-flow: column;
  width: 100%;
  max-height: 600px;
}

table.questionsAnswerTable thead {
    padding-right: 13px;
    flex: 0 0 auto;
}

table.questionsAnswerTable tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
}

table.questionsAnswerTable tr {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.pulse-answer-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
}
