.radialbar-to-bar-wrapper {
  position: relative;
  width: 800px;
  height: 800px;
}

.toolTip,
.radialbar-to-bar-wrapper * {
  font: 11px sans-serif;
}

.radialbar-to-bar-wrapper text {
  pointer-events: none;
}

.axis text,
.radialbar-to-bar-wrapper text.legend {
  fill: #737373;
}

.axis path,
.axis line {
  /* fill: none; */
  stroke: #D4D8DA;
  stroke-width: 1px;
  shape-rendering: crispEdges;
}

.radialbar-to-bar-wrapper text.area-legend {
  pointer-events: all !important;
  cursor: pointer;
  fill: #000;
}

.toolTip {
  pointer-events: none;
  position: absolute;
  display: inline-block;
  min-width: 40px;
  height: auto;
  background: none repeat scroll 0 0 #ffffff;
  padding: 9px 14px 6px 14px;
  border-radius: 2px;
  text-align: center;
  font-size: 12px;
  font-family: sans-serif;
  line-height: 1.3;
  color: #5B6770;
  box-shadow: 0px 3px 9px rgba(0, 0, 0, .15);
}

.toolTip:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid white;
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -12px;
}

.toolTip span {
  font-weight: 500;
  color: #081F2C;
}

.bar-container .bar {
  fill: #1f77b4;
}

.bar-container .bar:hover {
  fill-opacity: 0.8;
}

.bar-container .line {
  fill: none;
  /* stroke: #c00000; */
  stroke: #f17e15;
  stroke-width: 3;
  pointer-events: none;
}

circle.radialbar-chart-serie0 {
  fill: #f17e15;
}

circle.radialbar-chart-serie1 {
  fill: #4dc9f6;
}

polygon.radialbar-chart-serie0 {
  fill: #f17e15;
  stroke: #f17e15;
}

polygon.radialbar-chart-serie1 {
  fill: #4dc9f6;
  stroke: #4dc9f6;
}

.bar-container .path {
  fill: none;
  stroke: rgb(77, 201, 246);
  stroke-width: 3;
  pointer-events: none;
}

.trends-container *,
.trends-container{
  pointer-events: all !important;
}

.trends-container .path {
  fill: none;
  stroke: rgb(77, 201, 246);
  stroke-width: 3;
  pointer-events: none;
}

.trends-container .point {
  fill: rgb(77, 201, 246);
  stroke: rgb(77, 201, 246);
  stroke-width: 0;
  pointer-events: none;
}

.d3-hide {
  display: none !important;
}

.d3-hidden {
  visibility: hidden !important;
}

.trends {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  /* border: 1px solid #3284ba; */
  border: 0;
  background: rgba(77, 201, 246, 1);
  padding: 5px 15px;
  border-radius: 4px;
  /* color: #000; */
  color: #fff;
  width: 80px;
  cursor: pointer;
  margin: 10px auto;
  text-align: center;
  font-size: 12px !important;
}

.trends:hover {
  /* background: rgba(77, 201, 246, 0.3); */
  background: rgba(77, 201, 246, 0.8);
}

.go-back {
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  /* border: 1px solid #3284ba; */
  border: 0;
  background: rgba(77, 201, 246, 1);
  padding: 5px 15px;
  border-radius: 4px;
  /* color: #000; */
  color: #fff;
  width: 80px;
  cursor: pointer;
  margin: 10px auto;
  text-align: center;
  font-size: 12px !important;
}

.go-back:hover {
  /* background: rgba(77, 201, 246, 0.3); */
  background: rgba(77, 201, 246, 0.8);
}

/* https://rasmusfonseca.github.io/d3RangeSlider/ */

.range-slider {
  position: absolute;
  margin: 0 25px;
  /* width: 100%; */
  width: calc(100% - 50px);
  height: 10px;
  bottom: 30px;
}

.range {
  fill: #2fbaf2;
}

.slider-container {
  background-color: #f2f2f9;
}

.slider-background {
  background-color: #eeeeee;
  position: absolute;
  height: 10px;
  min-width: 20px;
  width: 100%;
  /* width: calc(100% - 20px); */
}

.slider {
  position: absolute;
  /* border: 1px solid #AAB; */
  border: 1px solid #2fbaf2;
  background: #2fbaf2;
  height: 100%;
  min-height: 10px;
  width: 58px;
  top: 0px;
  cursor: move;
  /*margin:-0.5px;*/
}

.slider .handle {
  position: absolute;
  height: 24px;
  width: 30px;
  border: 2px solid #dadada;
  border-radius: 4px;
  background: #fff;
  /* Support for bootstrap */
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

.handle-label {
  position: absolute;
  /* height: 24px; */
  min-width: 40px;
  border: 2px solid #2fbaf2;
  border-radius: 4px;
  background: #fff;
  padding: 6px 10px;
}

.slider .EE {
  right: -6px;
  cursor: e-resize;
}

.handle-EE-label {
  right: -22px;
}

.slider .WW {
  cursor: w-resize;
  left: -6px;
}

.handle-WW-label {
  left: -22px;
}

.slider .EE,
.slider .WW {
  top: 50%;
  margin-top: -12px;
}

.handle-EE-label,
.handle-WW-label {
  top: -30px;
  margin-top: -12px;
  pointer-events: none;
}

.no-data{
  text-anchor: middle;
}