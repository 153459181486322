#copyLinkTextarea{
    height: 44px;
    resize: none;
    width: 100%;
    border: 0;
    padding: 8px;
}

.copy-link-btn {
  flex-shrink: 0;
  height: 44px;
}

.copy-link-body-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #49494963;
  border-radius: 4px;
}
