.DayPicker-Day--partiallyassigned {
  background-color: orange;
  color: white;
}

.DayPicker-Day--assigned {
  background-color: #82e0aa;
  color: white;
}

.colorLightGrey {
  background-color: #efefef;
}

.colorGreen {
  background-color: #82e0aa;
}

.colorOrange {
  background-color: #ffbc42;
}

.colorBlue {
  background-color: #84c6ff;
}

.colorLightBlue {
  background-color: #a6d5fe;
}

.btn-success {
  background: #82e0aa !important;
}

.legend_paragraph {
  font: 12px "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 14px;
  margin: 0;
  padding: 10px;
}

.legend_dot1 {
  height: 25px;
  width: 25px;
  background-color: #84c6ff;
  border-radius: 50%;
  display: inline-block;
  float: left;
  margin-right: 6px;
}

.legend_dot2 {
  height: 25px;
  width: 25px;
  background-color: orange;
  border-radius: 50%;
  display: inline-block;
  float: left;
  margin-right: 6px;
}

.legend_dot3 {
  height: 25px;
  width: 25px;
  background-color: #82e0aa;
  border-radius: 50%;
  display: inline-block;
  float: left;
  margin-right: 6px;
}

#copyPasteTooltipPanel {
  line-height: 340px;
  height: 340px;
  border: 1px solid gray;
  text-align: center;
}

#copyPasteTooltipPanel div {
  line-height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.form-control {
  border: 1px solid lightgray !important;
  border-radius: 5px !important;
}
